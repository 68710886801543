<template>
  <div class="p-2 mx-2 mb-2 text-xs text-gray-500">
    <a class="hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-500" href="mailto:info@matchheft.ch">{{$t('support')}}</a> ·
    <router-link class="hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-500" to="/privacy">{{$t('privacy')}}</router-link> ·
    <router-link class="hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-500" to="/terms">{{$t('terms')}}</router-link><br>
    <span class="text-gray-500">Matchheft © 2024</span>
  </div>
</template>

<script>
export default {
}
</script>
